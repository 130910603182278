import React from "react";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox,
} from "mdb-react-ui-kit";

// import "./Login.css";
import { useState } from "react";
import { useLogin } from "../hooks/useLogin";

import { useNavigate } from "react-router-dom";

// import logo from "../icons/logo.png";

// import logo1 from "../icons/logo.png";

import logo2 from "../images/iot.png";
// import logo2 from "../images/VLogoWebp.webp";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await login(email, password);
  };

  const navigate = useNavigate();

  const navigateToSignup = () => {
    navigate("/Signup");
  };

  return (
    <form onSubmit={handleSubmit}>
      <MDBContainer fluid className="p-3 my-5 h-custom">
        <MDBRow>
          <MDBCol col="10" md="6">
            {/* <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              class="img-fluid"
              alt="Sample image"
            /> */}
            <img src={logo2} class="img-fluid" alt="Sample image" />
          </MDBCol>

          <MDBCol col="4" md="6">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <p className="lead fw-normal mb-0 me-3">Sign in </p>
              {/* <p className="lead fw-normal mb-0 me-3">Sign in with</p> */}

              {/* <MDBBtn floating size="md" tag="a" className="me-2">
                <MDBIcon fab icon="facebook-f" />
              </MDBBtn>

              <MDBBtn floating size="md" tag="a" className="me-2">
                <MDBIcon fab icon="twitter" />
              </MDBBtn>

              <MDBBtn floating size="md" tag="a" className="me-2">
                <MDBIcon fab icon="linkedin-in" />
              </MDBBtn> */}
            </div>

            {/* <div className="divider d-flex align-items-center my-4">
              <p className="text-center fw-bold mx-3 mb-0">Or</p>
            </div> */}

            <MDBInput
              wrapperClass="mb-4"
              label="Email address"
              id="email"
              type="email"
              size="lg"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Password"
              id="Password"
              // type="password"
              type={showPassword ? "text" : "password"}
              size="lg"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            {/* <div className="d-flex justify-content-between mb-4">
              <input
                id="check"
                type="checkbox"
                value={showPassword}
                onChange={() => setShowPassword((prev) => !prev)}
              />
              <a for="check">Show Password</a>
            </div> */}

            <div className="d-flex justify-content">
              <MDBCheckbox
                id="check"
                type="checkbox"
                value={showPassword}
                onChange={() => setShowPassword((prev) => !prev)}
              />
              <a for="check">Show Password</a>
            </div>

            <div className="d-flex justify-content-between mb-4">
              <MDBCheckbox
                name="flexCheck"
                value=""
                id="flexCheckDefault"
                label="Remember me"
              />
              <a href="/Signup">Forgot password?</a>
            </div>

            <div className="text-center text-md-start mt-4 pt-2">
              <MDBBtn className="mb-0 px-5" size="lg">
                Login
              </MDBBtn>
              <p className="small fw-bold mt-2 pt-1 mb-2">
                Don't have an account?{" "}
                <a href="/Signup" className="link-danger">
                  Signup here
                </a>
              </p>
            </div>
          </MDBCol>
        </MDBRow>

        <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
          <div className="text-white mb-3 mb-md-0">
            Copyright © 2022. All rights reserved.
          </div>

          <div className="text-white mb-3 mb-md-0">
            A product of Vincenzo Technology PVT. LTD.
          </div>

          {/* <div>
            <MDBBtn
              tag="a"
              color="none"
              className="mx-3"
              style={{ color: "white" }}
            >
              <MDBIcon fab icon="facebook-f" size="md" />
            </MDBBtn>

            <MDBBtn
              tag="a"
              color="none"
              className="mx-3"
              style={{ color: "white" }}
            >
              <MDBIcon fab icon="twitter" size="md" />
            </MDBBtn>

            <MDBBtn
              tag="a"
              color="none"
              className="mx-3"
              style={{ color: "white" }}
            >
              <MDBIcon fab icon="google" size="md" />
            </MDBBtn>

            <MDBBtn
              tag="a"
              color="none"
              className="mx-3"
              style={{ color: "white" }}
            >
              <MDBIcon fab icon="linkedin-in" size="md" />
            </MDBBtn>
          </div> */}
        </div>
      </MDBContainer>
    </form>
  );
}

export default LoginPage;
