// src/App.js

import React, { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import "./Userdetails1.css"; // Importing the CSS file for styling

const baseUrl = process.env.REACT_APP_API_URL;

const App = () => {
  const { user } = useAuthContext();
  const dataFetchedRef = useRef(false);

  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const apis = `${baseUrl}/api/userDetail`;

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        setUserDetails(data);
      });
  }, [user.token]);

  // Function to toggle activation status and update on the server
  const toggleActivation = (id, currentActivation) => {
    // Determine the new activation status
    const newActivation = currentActivation === "true" ? "false" : "true";

    // Update the local state
    setUserDetails((prevUserDetails) =>
      prevUserDetails.map((user) =>
        user._id === id ? { ...user, activation: newActivation } : user
      )
    );

    // Send the updated activation status to the server
    const userToUpdate = userDetails.find((user) => user._id === id);
    const updatedUserData = {
      ...userToUpdate,
      activation: newActivation,
    };

    // console.log("updatedUserData", updatedUserData);
    fetch(`${baseUrl}/api/deviceAssigned/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedUserData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update activation status");
        }
        return response.json();
      })
      .then((updatedUser) => {
        console.log("Updated user:", updatedUser);
      })
      .catch((error) => {
        console.error("Error updating activation status:", error);
      });
  };

  return (
    <div className="container">
      <h1 className="title">User Data Table</h1>
      <table className="user-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Role</th>
            <th>Activation</th>
            <th>DeviceType</th>
            <th>DeviceIDDetails</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {userDetails.map((user) => (
            <tr key={user._id} className="table-row">
              <td>{user._id}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td
                className={`status ${
                  user.activation === "true" ? "enabled" : "disabled"
                }`}
              >
                {user.activation === "true" ? "Enabled" : "Disabled"}
              </td>
              <td>{user.deviceType}</td>
              <td>{user.deviceIDDetails.join(", ")}</td>
              <td>
                <button
                  className={`toggle-button ${
                    user.activation === "true" ? "disable" : "enable"
                  }`}
                  onClick={() => toggleActivation(user._id, user.activation)}
                >
                  {user.activation === "true" ? "Disable" : "Enable"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default App;
