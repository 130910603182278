import React, { useState, useEffect, useRef } from "react";
import { GiComputerFan, GiCeilingLight } from "react-icons/gi";
import { HiLightBulb } from "react-icons/hi";
import { PiFanFill } from "react-icons/pi";
import { SiHomeassistant } from "react-icons/si";
import { BsLampFill } from "react-icons/bs";
import { useAuthContext } from "../hooks/useAuthContext";
import "./Sw4r.css";
import Switch from "react-switch";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import io from "socket.io-client";

const baseUrl = process.env.REACT_APP_API_URL;
const socket = io.connect(baseUrl);

const Sw4r = ({ deviceID }) => {
  const { user } = useAuthContext();
  const [currentData, setCurrentData] = useState({});
  const [fanSpeed, setFanSpeed] = useState(0);
  const [loadStates, setLoadStates] = useState({
    load1: false,
    load2: false,
    load3: false,
    fan1: false,
  });

  const dataFetchedRef = useRef(false);

  const updateDeviceState = async (updates) => {
    const response = await fetch(
      `${baseUrl}/api/sensorDatas/ezymoteHA3L1FMQTT`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...currentData, ...updates }),
      }
    );
    return response.ok;
  };

  const fetchSensorData = async () => {
    const response = await fetch(
      `${baseUrl}/api/sensorDatas/ezymoteHA3L1F/${deviceID}/1`,
      {
        headers: { Authorization: `Bearer ${user.token}` },
      }
    );
    const json = await response.json();
    if (response.ok) {
      setCurrentData(json[0]);
      setLoadStates({
        load1: json[0].load1 === "on",
        load2: json[0].load2 === "on",
        load3: json[0].load3 === "on",
        fan1: json[0].fan1 === "on",
      });
      setFanSpeed(parseInt(json[0].fan1Speed, 10));
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchSensorData();
  }, [deviceID]);

  useEffect(() => {
    socket.on(deviceID, (data) => {
      setCurrentData(data);
      setLoadStates({
        load1: data.load1 === "on",
        load2: data.load2 === "on",
        load3: data.load3 === "on",
        fan1: data.fan1 === "on",
      });
      setFanSpeed(parseInt(data.fan1Speed, 10));
    });
  }, [socket, deviceID]);

  const handleLoadToggle = async (loadKey) => {
    const newState = !loadStates[loadKey];
    setLoadStates({ ...loadStates, [loadKey]: newState });
    await updateDeviceState({ [loadKey]: newState ? "on" : "off" });
  };

  const handleFanSpeedChange = async (speed) => {
    setFanSpeed(speed);
    await updateDeviceState({ fan1Speed: speed });
  };

  return (
    <div className="parent">
      <div className="child1">
        <div className="homeautomation-container">
          <p
            style={{
              color: "black",
              fontSize: "20px",
              textAlign: "left",
              padding: "5px",
            }}
          >
            <SiHomeassistant
              size={60}
              style={{ color: "#08C2F3", fontSize: "50px" }}
            />
            Welcome, <b>{user.userName}</b>
          </p>
          <center>Device ID: {currentData.deviceID}</center>
          <center>WiFi SS: {currentData.csq}</center>
        </div>
      </div>

      {["load1", "load2", "load3", "fan1"].map((loadKey, index) => (
        <div className="child1" key={loadKey}>
          <div className="homeautomation-buttoncontainer">
            {index === 0 && (
              <GiCeilingLight
                size={40}
                style={{ color: loadStates[loadKey] ? "#08C2F3" : "gray" }}
              />
            )}
            {index === 1 && (
              <BsLampFill
                size={40}
                style={{ color: loadStates[loadKey] ? "#08C2F3" : "gray" }}
              />
            )}
            {index === 2 && (
              <HiLightBulb
                size={40}
                style={{ color: loadStates[loadKey] ? "#08C2F3" : "gray" }}
              />
            )}
            {index === 3 && (
              <PiFanFill
                size={40}
                style={{ color: loadStates[loadKey] ? "#08C2F3" : "gray" }}
              />
            )}

            <Switch
              checked={loadStates[loadKey]}
              onChange={() => handleLoadToggle(loadKey)}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              height={12}
              width={40}
            />
            <br />
            <center>
              <b>
                {loadKey
                  .replace("load", "Ceiling Light ")
                  .replace("fan1", "Fan")}
              </b>
            </center>
          </div>
        </div>
      ))}

      <div className="child1">
        <div className="homeautomation-fanregulatorcontainer">
          <Box sx={{ width: 250 }}>
            <Slider
              value={Number.isFinite(fanSpeed) ? fanSpeed : 0}
              onChange={(e, value) => setFanSpeed(value)}
              onChangeCommitted={(e, value) => handleFanSpeedChange(value)}
              aria-label="Fan Speed"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={5}
            />
          </Box>
          <center>
            <b>Fan Speed</b>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Sw4r;
