import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBTypography,
  MDBIcon,
} from "mdb-react-ui-kit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL; // Ensure this environment variable is set

const App = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError(null); // Reset error on input change
  };

  const validateForm = () => {
    const { firstName, email, subject, message } = formData;
    if (!firstName || !email || !subject || !message) {
      return "All fields are required.";
    }
    // Add more validation as needed, such as email format
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/send-email`, formData);
      alert(response.data.message); // Customize this alert based on the response
      setFormData({ firstName: "", email: "", subject: "", message: "" }); // Reset form
    } catch (error) {
      setError("There was an error sending the email.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="parent">
      <MDBCard className="text-center mb-4">
        <MDBCardBody>
          <MDBTypography tag="h3" className="mb-4">Contact Us</MDBTypography>
          <MDBTypography tag="h5" className="mb-4">
            In case you find any issues in the cloud, please report to us
            through email or any of the following ways for a faster resolution of your
            issues.
          </MDBTypography>

          <MDBRow>
            <MDBCol lg="7">
              <form onSubmit={handleSubmit}>
                <MDBRow className="mb-4">
                  <MDBCol md="6">
                    <MDBInput
                      label="First name"
                      id="form3FirstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </MDBCol>
                  <MDBCol md="6">
                    <MDBInput
                      label="Email Address"
                      id="form3Email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBInput
                  type="text"
                  label="Subject"
                  id="form3Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  wrapperClass="mb-4"
                  required
                />
                <MDBTextArea
                  label="Message"
                  id="form3Textarea"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  wrapperClass="mb-4"
                  required
                />
                {error && <p className="text-danger">{error}</p>}
                <MDBBtn color="primary" type="submit" disabled={loading}>
                  {loading ? "Sending..." : "Send"}
                </MDBBtn>
              </form>
            </MDBCol>
            <MDBCol lg="5">
              <ul className="list-unstyled">
                <li className="mb-3">
                  <MDBIcon icon="map-marker-alt" size="2x" className="text-primary" />
                  <p>
                    <small>Budheswari Colony, Bhubaneswar, Odisha 751006</small>
                  </p>
                </li>
                <li className="mb-3">
                  <MDBIcon icon="phone" size="2x" className="text-primary" />
                  <p>
                    <small>+91 8270441234</small>
                  </p>
                </li>
                <li className="mb-3">
                  <MDBIcon icon="envelope" size="2x" className="text-primary" />
                  <p>
                    <small>sarojflame@gmail.com</small>
                  </p>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default App;
